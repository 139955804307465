import { useState, useEffect, createContext, useContext } from 'react';

import AvatarSvg from './avatar.svg';
import LeaderSvg from './leader.svg';
import ProSvg from './checkmark.svg';
import ContraSvg from './checkcross.svg';

import './App.css';

import { HashRouter, Routes, Route, useNavigate, useParams } from "react-router-dom";

const GameContext = createContext({
  players: [],
  others: [],
  me: {},
  votes: {},
  vote_result: undefined,
  num_votes: 0,
  num_participants: 0,
  required_participants: 0,
  round_results: [],
  handleNameChange() { },
  handleParticipantChange() { },
  handleStartGame() { },
  handleVoteChange() { },
  handleVoteNow() { },
});

const STATES = Object.freeze({
  LOBBY: "lobby",
  CHOOSING_TEAM: "choosing_team",
  VOTING: "voting",
  VOTE_RESULT: "vote_result",
  MISSION_VOTE: "mission_vote",
  MISSION_RESULT: "mission_result",
})

function Mission({ num, participants, result }) {
  return <div className="mission circle">
    { result !== undefined && <img className="mission-result" src={result ? ProSvg : ContraSvg}></img>}
    { result === undefined && <>
      <span style={{ whiteSpace: 'nowrap' }}>Mission {num}</span>
      <span className="mission-number">{participants}</span>
    </>}
  </div>;
}

function Missions() {
  const { round_results }  = useContext(GameContext);
  return <div className="missions">
    {[2, 3, 2, 3, 3].map((v, i) => <Mission key={i} num={i + 1} participants={v} result={round_results[i]} />)}
  </div>;
}

function Vote({ num }) {
  const { num_votes } = useContext(GameContext);
  let voteClassNames = ['vote'];
  if (num == num_votes) {
    voteClassNames.push('current_vote');
  }
  return <div className={voteClassNames.join(' ')}>
    <div className='voteContent'>
      {num}
    </div>
  </div>;
}

function Votes() {
  return <div className="votes">
    {[1, 2, 3, 4, 5].map((v, i) => <Vote num={v} />)}
  </div>;
}

function StateLabel({state, current_state, children}) {
  return current_state === state && children;
}

function Board() {
  const {
    num_participants,
    required_participants,
    handleVoteNow,
    status,
    me,
    vote_result,
    round_results,
    timeout,
  } = useContext(GameContext);

  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="board board-box">
      <Missions />
      <Votes />
      <div className="board-info">
        <div className="state-label">
          <StateLabel state={STATES.CHOOSING_TEAM} current_state={status}>
            {me.leader ? "Select a team for your mission!": "The leader selects a team..."}
          </StateLabel>
          <StateLabel state={STATES.VOTING} current_state={status}>
            Voting in progress...
          </StateLabel>
          <StateLabel state={STATES.VOTE_RESULT} current_state={status}>
            {vote_result ? "Team was accepted!": "Team was rejected!"}
          </StateLabel>
          <StateLabel state={STATES.MISSION_VOTE} current_state={status}>
            {me.participant ? "Vote if you want this mission to succeed...": "Mission in progress, hope for the best..."}
          </StateLabel>
          <StateLabel state={STATES.MISSION_RESULT} current_state={status}>
            {round_results[round_results.length - 1] ? "The mission succeeded!": "The mission was a failure!"}
          </StateLabel>
        </div>
        { timeout && <div className="timeout">{((timeout - now)/1000).toFixed(1)}</div>}
        { status === STATES.CHOOSING_TEAM && <button
          onClick={() => handleVoteNow()}
          disabled={num_participants != required_participants}
          >Start Voting Now! {num_participants}/{required_participants}
        </button> }
      </div>
    </div>
  );
}

function Avatar({ name, leader, participant, role, toggleSelect }) {
  let avatarClasses = ['avatar', 'card-background'];
  if (['spy', 'resistance'].includes(role)) {
    avatarClasses.push(role);
  }
  if (participant) {
    avatarClasses.push('participant');
  }
  return <div className={avatarClasses.join(' ')} onClick={() => toggleSelect && toggleSelect()}>
    <img className="avatar-img" alt={name} src={AvatarSvg}></img>
    <div className="leaderPlaceholder">
      {leader && <img alt="Leader" src={LeaderSvg}></img>}
    </div>
    <div className='avatar-name'>{name}</div>
  </div>;
}

function VoteCards({ player }) {
  const { status } = useContext(GameContext);

  let cardClassNames = ['vote-card'];
  if (player.hasOwnProperty('vote')) {
    cardClassNames.push('vote-selected');
  }

  return <div className='vote-selection other'>
    <div className='vote-card' />
    <div className={cardClassNames.join(' ')}>
      { player.vote === true && <img className="vote-result" src={ProSvg}></img>}
      { player.vote === false && <img className="vote-result" src={ContraSvg}></img>}
    </div>
  </div>
}

function OtherPlayer({ player }) {
  const { handleParticipantChange } = useContext(GameContext);
  return <div className="other-player">
    <Avatar
      name={player.name}
      leader={player.leader}
      role={player.role}
      participant={player.participant}
      toggleSelect={() => handleParticipantChange(player.id)}
    />
    <VoteCards player={player} />
  </div>;
}

function OtherPlayers() {
  const { others } = useContext(GameContext);
  return <div className="other-players">
    {others.map(player => <OtherPlayer
      key={player.id}
      player={player}
    />)}
  </div>;
}

function OwnSelection() {
  const { handleVoteChange, me, status } = useContext(GameContext);
  let proClassNames = ['vote-card'];
  if (me.vote === true) {
    proClassNames.push('vote-selected');
  }
  let contraClassNames = ['vote-card'];
  if (me.vote === false) {
    contraClassNames.push('vote-selected');
  }

  let voteSelectionClassNames = ['vote-selection'];
  if (status == STATES.VOTING || status == STATES.MISSION_VOTE && me.participant) {
    voteSelectionClassNames.push('voting');
  }

  return <div className={voteSelectionClassNames.join(' ')}>
    <div
      className={proClassNames.join(' ')}
      onClick={e => handleVoteChange(me.vote === true ? undefined : true)}
    >
      <img alt="pro" src={ProSvg}></img>
    </div>
    <div
      className={contraClassNames.join(' ')}
      onClick={e => handleVoteChange(me.vote === false ? undefined : false)}
    >
      <img alt="contra" src={ContraSvg}></img>
    </div>
  </div>;
}

function OwnPlayer() {
  const { me, handleParticipantChange } = useContext(GameContext);
  return <div className="own-player">
    <Avatar
      name={me.name}
      leader={me.leader}
      participant={me.participant}
      role={me.role}
      toggleSelect={() => handleParticipantChange(me.id)}
    />
    <OwnSelection />
  </div>;
}

function Field() {
  return <div className="field">
    <OtherPlayers />
    <Board />
    <OwnPlayer />
  </div>
}

function Index() {
  const navigate = useNavigate();
  const createLobby = async () => {
    console.log('creating lobby...');
    const opts = { method: 'POST', body: '' };
    const res = await fetch('/create_lobby', opts).then(response => response.json());
    console.log(`created lobby: ${res.lobby_id}`);
    console.log(res);
    navigate(`/lobby/${res.lobby_id}`)
  };
  return <div>
    <h1>The Resistance</h1>
    <button onClick={createLobby}>Create Lobby</button>
  </div>;
}

function playerFromState(state, player_id) {
  let player = { id: player_id };
  player.name = state.names[player_id];
  player.role = state.roles[player_id];
  player.leader = player_id === state.leader;
  player.participant = state.participants.includes(player_id);
  if (state.votes.hasOwnProperty(player_id)) {
    player.vote = state.votes[player_id];
  }
  return player;
}

function Lobby() {
  const { players, me, num_participants, handleNameChange, handleParticipantChange, handleStartGame } = useContext(GameContext);
  return <div style={{ display: 'flex', flexDirection: 'column', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
    <div className="lobbyPlayers">
      {players.map(player => <Avatar
        key={player.id}
        name={player.name}
        leader={player.leader}
        participant={player.participant}
        toggleSelect={() => handleParticipantChange(player.id)}
      />)}
    </div>
    <div className="mission-form board-box">
      <form action="#" onSubmit={(e) => { e.preventDefault(); handleStartGame(); }}>
        <div className="form-pair">
          <label>Number <span style={{ whiteSpace: 'nowrap'}}>of Players</span></label>
          <input type="number" min="5" max="10" defaultValue={5} disabled></input>
        </div>
        <div className="form-pair">
          <span>Members <span style={{ whiteSpace: 'nowrap'}}>per Mission</span></span>
          <div className="mission-configs">
            <div className="mission-config">
              <label>Mission 1</label>
              <input type="number" min="1" max="10" defaultValue={2} disabled></input>
            </div>
            <div className="mission-config">
              <label>Mission 2</label>
              <input type="number" min="1" max="10" defaultValue={3} disabled></input>
            </div>
            <div className="mission-config">
              <label>Mission 3</label>
              <input type="number" min="1" max="10" defaultValue={2} disabled></input>
            </div>
            <div className="mission-config">
              <label>Mission 4</label>
              <input type="number" min="1" max="10" defaultValue={3} disabled></input>
            </div>
            <div className="mission-config">
              <label>Mission 5</label>
              <input type="number" min="1" max="10" defaultValue={3} disabled></input>
            </div>
          </div>
        </div>
        <div className="form-pair">
          <label>Choose Name</label>
          <input type="text" value={me.name ?? ""} maxlength="10" onChange={e => handleNameChange(e.target.value)}></input>
        </div>
        <div className="buttons">
          <div className="copy-link">
            <button>Copy Link</button>
          </div>
          <div className="start-game">
            <button type="submit" disabled={num_participants !== 5}>Start Game! {num_participants}/5</button>
          </div>
        </div>
      </form>
    </div>
  </div>;
}

function timeoutIn(secs) {
  let timeout = new Date();
  timeout.setSeconds(timeout.getSeconds() + secs);
  return timeout;
}

function Management() {
  const { lobby_id } = useParams();
  const [state, setState] = useState({
    players: [],
    names: {},
    roles: {},
    participants: [],
    round: 0,
    participants_per_round: [2,3,2,3,3],
    required_participants: 2,
    round_results: [],
    num_votes: 0,
    my_id: undefined,
    leader: undefined,
    votes: {},
    status: STATES.LOBBY,
  });
  const [ws, setWs] = useState(undefined);
  useEffect(() => {
    console.log("opening websocket", lobby_id);
    let url = new URL(`/ws/${lobby_id}`, window.location.href);
    url.protocol = url.protocol.replace('http', 'ws');
    const thisws = new WebSocket(url.href);
    thisws.addEventListener('message', event => {
      const payload = JSON.parse(event.data);

      if (payload.type === 'connected_users') {
        setState(s => ({ ...s, names: payload.users }));
      }
      if (payload.type === 'your_id') {
        setState(s => ({ ...s, my_id: payload.id }));
      }
      if (payload.type === 'selected_team') {
        console.log(payload);
        setState(s => ({ ...s, participants: payload.team }));
      }
      if (payload.type === 'start_game') {
        console.log(payload);
        setState(s => ({
          ...s,
          roles: payload.known_roles,
          players: payload.players,
          leader: payload.leader,
          status: STATES.CHOOSING_TEAM,
          participants_per_round: payload.required_participants,
          participants: [],
        }));
      }

      if (payload.type === 'vote_status') {
        setState(s => ({
          ...s,
          votes: payload.votes,
        }));
      }

      if (payload.type === 'choose_team') {
        setState(s => ({
          ...s,
          leader: payload.leader,
          num_votes: payload.vote,
          status: STATES.CHOOSING_TEAM,
          participants: [],
          votes: {},
          timeout: timeoutIn(payload.timeout_secs),
        }));
      }

      if (payload.type === 'vote_start') {
        setState(s => ({
          ...s,
          participants: payload.participants,
          status: STATES.VOTING,
          votes: {},
          timeout: timeoutIn(payload.timeout_secs),
        }));
      }

      if (payload.type === 'vote_result') {
        console.log(payload);
        setState(s => ({
          ...s,
          votes: payload.votes,
          status: STATES.VOTE_RESULT,
          vote_result: payload.result,
          timeout: undefined,
        }));
      }

      if (payload.type === 'mission_start') {
        setState(s => ({
          ...s,
          participants: payload.participants,
          status: STATES.MISSION_VOTE,
          votes: {},
          timeout: timeoutIn(payload.timeout_secs),
        }));
      }

      if (payload.type === 'mission_vote_status') {
        setState(s => ({
          ...s,
          votes: payload.votes,
        }));
      }

      if (payload.type === 'mission_result') {
        setState(s => ({
          ...s,
          mission_votes: payload.mission_votes,
          round_results: s.round_results.push(payload.result),
          status: STATES.MISSION_RESULT,
          timeout: undefined,
        }));
      }

      if (payload.type === 'start_round') {
        setState(s => ({
          ...s,
          round_results: payload.round_results,
          required_participants: payload.required_participants,
        }));
      }
    });
    setWs(thisws);
    return () => { console.log("closing websocket"); thisws.close(); };
  }, [lobby_id]);

  const handleNameChange = name => {
    ws.send(JSON.stringify({ type: 'set_name', value: name }));
  }

  const handleParticipantChange = participant => {
    let participants = [...state.participants];
    if (participants.includes(participant)) {
      participants.splice(participants.indexOf(participant), 1);
    } else {
      participants.push(participant);
    }
    ws.send(JSON.stringify({ type: 'choose_team', value: [...participants] }));
  }

  const handleStartGame = () => ws.send(JSON.stringify({ type: 'start_game' }));

  const handleVoteNow = () => { ws.send(JSON.stringify({ type: 'vote_now' })); };
  const handleVoteChange = (vote) => { ws.send(JSON.stringify({ type: 'set_vote', value: vote })) };

  const players = Object.keys(state.names).map(id => playerFromState(state, id));
  let me = playerFromState(state, state.my_id);

  return <div>
    <GameContext.Provider value={{
      players,
      others: players.filter(player => player.id !== me.id),
      me,
      participants: state.participants,
      votes: state.votes,
      leader: state.leader,
      num_votes: state.num_votes,
      round_results: state.round_results,
      status: state.status,
      vote_result: state.vote_result,
      timeout: state.timeout,
      num_participants: Object.keys(state.participants).length,
      required_participants: state.required_participants,
      handleNameChange,
      handleParticipantChange,
      handleStartGame,
      handleVoteChange,
      handleVoteNow,
    }}>
      {state.status === STATES.LOBBY && <Lobby />}
      {state.status !== STATES.LOBBY && <Field />}
    </GameContext.Provider>
  </div>;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HashRouter>
          <Routes>
            <Route index element={<Index />}></Route>
            <Route path="lobby/:lobby_id" element={<Management />}></Route>
            <Route path="game" element={<Field />}></Route>
          </Routes>
        </HashRouter>
      </header>
    </div>
  );
}

export default App;
